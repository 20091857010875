import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './advertisement.module.scss'
import { Advertisement } from './index'

enum AdvertisementLayout {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL'
}

interface AdvertisementGroupProps extends PropsWithChildren {
  layout?: AdvertisementLayout
  adType?: string
  adCount?: number
}

const AdvertisementGroup: FunctionComponent<AdvertisementGroupProps> = (
  {
    layout = AdvertisementLayout.HORIZONTAL,
    adType,
    adCount= 4,
    children
  }
) => {
  let content

  if (adType) {
    content = Array(adCount)
      .fill(null)
      .map((_, index) =>
        <Advertisement key={index} adType={adType}/>
      )
  } else {
    content = children
  }

  return <div className={clsx(
    styles.group,
    layout === AdvertisementLayout.VERTICAL && styles.groupVertical,
    layout === AdvertisementLayout.HORIZONTAL && styles.groupHorizontal
  )}>
    {content}
  </div>
}

export default AdvertisementGroup

export {
  AdvertisementLayout
}
export type {
  AdvertisementGroupProps,
}
